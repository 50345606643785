import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  messages: [],
  whatsAppMessages: [],
  users: [],
  whatsAppUsers: [],
  selectedUser: {},
  selectedWhatsAppUser: {},
  qr: '',
  contactInfo: [],
  newApplies: '',
  newReview: '',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'qr':
      return { ...state, qr: rest.data }
    case 'setSelectedUser':
      return { ...state, selectedUser: { ...rest.data } }
    case 'setWhatsAppSelectedUser':
      console.log('rest.data', rest.data)
      return { ...state, selectedWhatsAppUser: { ...rest.data } }
    case 'addMessage':
      if (+rest.message?.chatId !== +state.selectedUser?.chatId) {
        return {
          ...state,
          users: state.users.map((item) => {
            if (+item.chatId === +rest.message.chatId) {
              return {
                ...item,
                newMessege: true,
              }
            }
            return item
          }),
        }
      }
      return { ...state, messages: [...state.messages, rest.message] }
    case 'addWhatsAppMessage':
      if (rest.message?.phoneNumber !== state.selectedWhatsAppUser?.phoneNumber) {
        return {
          ...state,
          whatsAppUsers: state.whatsAppUsers.map((item) => {
            if (item.phoneNumber === rest.message.phoneNumber) {
              return {
                ...item,
                newMessege: true,
              }
            }
            return item
          }),
        }
      }
      console.log('rest.message', rest.message)
      return { ...state, whatsAppMessages: [...state.whatsAppMessages, rest.message] }
    case 'setAllMessage':
      return { ...state, messages: [...state.messages, ...rest.data] }
    case 'setAllWhatsAppMessage':
      return { ...state, whatsAppMessages: [...state.whatsAppMessages, ...rest.data] }
    case 'clearMessage':
      return { ...state, messages: [] }
    case 'clearWatsAppMessage':
      return { ...state, whatsAppMessages: [] }
    case 'addUser':
      return { ...state, users: [...state.users, rest.data] }
    case 'setAllUsers':
      return { ...state, users: [...state.users, ...rest.data] }
    case 'addWhatsAppUser':
      console.log(rest.data)
      return { ...state, whatsAppUsers: [...state.whatsAppUsers, ...rest.data] }
    case 'setAllWhatsAppUsers':
      return { ...state, whatsAppUsers: [...state.whatsAppUsers, ...rest.data] }
    case 'allMessagesAreSeen':
      return {
        ...state,
        users: state.users.map((item) => {
          if (item.id === rest.id) {
            return {
              ...item,
              newMessege: false,
            }
          }
          return item
        }),
      }
    case 'allWhatsAppMessagesAreSeen':
      return {
        ...state,
        whatsAppUsers: state.whatsAppUsers.map((item) => {
          if (item.id === rest.id) {
            return {
              ...item,
              newMessege: false,
            }
          }
          return item
        }),
      }
    case 'newApply':
      return { ...state, newApplies: rest.data }
    case 'newReview':
      return { ...state, newReview: rest.data }
    case 'seenReview':
      return { ...state, newReview: rest.data }
    case 'oneIsSee':
      return { ...state, newApplies: +state.newApplies - 1 }
    case 'setAllContact':
      return { ...state, contactInfo: [...state.contactInfo, ...rest.data] }
    case 'addContact':
      console.log('rest', rest)
      return { ...state, contactInfo: [...state.contactInfo, rest.data] }
    case 'editeContact':
      return {
        ...state,
        contactInfo: state.contactInfo.map((item) => {
          if (rest.data.id === item.id) {
            return {
              ...rest.data,
            }
          }
          return item
        }),
      }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
